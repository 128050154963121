<template>
  <v-container class="max-container">
    <v-card class="pa-3 card-box" flat dark color="black">
      <div class="desc-space" />
      <div class="center-box">
        <img src="@/assets/coin.png" alt="coin">

        <div class="available-points-box">
          <div class="text-title">Available points</div>
          <div class="points-value">{{ inviteInfo.total_score }}</div>
        </div>
        <div class="redeem-rules-desc">Redeem <span class="weight-text">USD $1 </span> reward value in your next subscription with <span class="weight-text">1 points</span>.</div>
        <div class="redeem-box">
          <v-text-field
            v-model="pointsValue"
            filled
            prepend-inner-icon="mdi-currency-usd"
            class="points-input"
            type="number"
            inputmode="numeric"
            :rules="numberRules"
          />
        </div>
        <v-btn :loading="btnLoading" :disabled="btnLoading" block depressed class="tile text-capitalize sub-btn" @click="redeem">Redeem now</v-btn>
      </div>

    </v-card>
    <c-snack-bar ref="csnackbar" />
  </v-container>
</template>

<script>
import { getInviteCount, redeemPoints } from '@/api/user'
import CSnackBar from '@/views/auth/components/snackbar'

export default {
  name: 'Redeem',
  components: {
    CSnackBar
  },
  data() {
    return {
      btnLoading: false,
      inviteInfo: {},
      pointsValue: 0,
      numberRules: [
        v => {
          if (!v) {
            return 'Required'
          }

          var re = /(^[1-9]\d*$)/
          if (!re.test(v)) {
            return 'Invalid value entered'
          }

          if (this.inviteInfo.total_score === 0) {
            return 'Insufficient points balance'
          }

          if (parseInt(v) > this.inviteInfo.total_score) {
            return `Must be less than ${this.inviteInfo.total_score}`
          }

          return true
        }
      ]
    }
  },
  created() {
    this.getInviteCount()
  },
  methods: {
    getInviteCount() {
      getInviteCount().then(res => {
        this.inviteInfo = res.data
      })
    },
    redeem() {
      if (parseInt(this.pointsValue) === 0) {
        return
      }
      this.btnLoading = true
      redeemPoints({ 'points': parseInt(this.pointsValue) }).then(res => {
        if (res.code === 0) {
          this.btnLoading = false
          this.getInviteCount()
          this.$refs.csnackbar.show(res.message, 'success', 3000)
          return
        }
        this.$refs.csnackbar.show(res.message, 'error', 3000)
      }, () => {
        this.$refs.csnackbar.show('Something went wrong, please try again later', 'error', 3000)
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.desc-space{
        height: 30px;
}

.center-box{
        text-align: center;
        .redeem-rules-desc{
            margin-top: 30px;
        }
        .v-icon.v-icon{
                color: rgb(254, 219, 128)!important;
            }

        .redeem-box{
            margin-top: 20px;
        }
}

.available-points-box{
    font-weight: bold;
    .points-value{
        color: #E040FB;
        font-size: 1.5rem;
    }
}

.sub-btn{
    margin-top: 20px;
    font-weight: bold;
    letter-spacing: 0.08em;
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-size: 800;
}
</style>
