<template>
  <v-snackbar
    v-model="snackbar"
    class="snackbar-tip"
    :color="color"
    :timeout="timeout"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CSnackBar',
  data() {
    return {
      text: '',
      snackbar: false,
      color: 'info',
      timeout: 6000
    }
  },
  methods: {
    show(text, color = 'info', timeout = 6000) {
      this.text = text
      this.color = color
      this.snackbar = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
